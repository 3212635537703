body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.version-container {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.version {
  cursor: pointer;
  font-style: normal;
  text-decoration: none;
  margin-right: 6px;
}

.workspace-container, 
.snake-container {
  position: relative;
  display: block;
  height: 100vh;
  transition: filter 0.2s linear;
}
.workspace-container {
  width: 60%;
  &--blur {
    filter: blur(5px) brightness(30%);
  }
}
.snake-container {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &--blur {
    filter: blur(5px) brightness(30%);
  }
}