@mixin flex($dir:column, $justify:flex-start, $align:center) {
    display: flex;
    flex-direction: $dir;
    justify-content: $justify;
    align-items: $align;
}

.modal {
    @include flex(column, center);
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.4s ease-in;

    &--hidden {
        visibility: hidden;
    }

    &__content {
        @include flex();
        min-width: 600px;
        width: 50%;
        padding: 24px;
        background-color: white;
        box-shadow: 
            0px 3px 3px -2px rgba(0,0,0,0.2),
            0px 3px 4px 0px rgba(0,0,0,0.12),
            0px 1px 8px 0px rgba(0,0,0,0.12);
        border-radius: 4px;
        transition: transform 0.2s ease-in;
        position: relative;
        transform: translateY(0);

        &--hidden {
            transform: translateY(-100%);
        }
    }
}