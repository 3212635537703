@mixin box($dim) {
    width: $dim;
    height: $dim;
}

@mixin place($top:unset,$right:unset,$bottom:unset,$left:unset) {
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
}

.world {
    display: flex;
    flex-direction: row;
    border: 25px solid gray;
    &__col {
        display: flex;
        flex-direction: column;
        --light: #43a047;
        --dark : #00701a;
        
        &--even {
            --light: #00701a;
            --dark : #43a047;
        }

        & &__cell:nth-child(even) {
            background-color: var(--light);
        }
        & &__cell:nth-child(odd) {
            background-color: var(--dark);
        }



        &__cell {
            --size: 30px;
            width: var(--size);
            height: var(--size);
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            
            &__content {
                background-color: black;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                position: relative;
                
                &--body {
                    &-1, &-2, &-3 {
                        @include box(15px);
                    }
                    
                    &-2 {
                        border-radius: 50%;
                    }

                    &-1, &-3 {
                        position: absolute;
                    }

                    &-1 {
                        @include place($left: 0);
                        margin: auto 0;

                        &.down {
                            @include place($top: 0);
                            margin: 0 auto;
                        }

                        &.left {
                            @include place($right: 0);
                            margin: auto 0;
                        }

                        &.up {
                            @include place($bottom: 0);
                            margin: 0 auto;
                        }
                    }
                    &-3 {
                        @include place($right: 0);
                        margin: auto 0;

                        &.down {
                            @include place($bottom: 0);
                            margin: 0 auto;
                        }

                        &.left {
                            @include place($left: 0);
                            margin: auto 0;
                        }

                        &.up {
                            @include place($top: 0);
                            margin: 0 auto;
                        }
                    }
                }
                
                &--head {
                    @include box(15px);
                    border-radius: 50% 0% 0% 50%;
                    margin: auto;
                    margin-right: 0;

                    &--up { 
                        transform: rotate(90deg);
                        margin: auto;
                        margin-bottom: 0;
                    }
                    &--right { 
                        transform: rotate(180deg);
                        margin: auto;
                        margin-left: 0; 
                    }
                    &--down { 
                        transform: rotate(270deg); 
                        margin: auto;
                        margin-top: 0; 
                    }
                }
                
                &--tail {
                    background-color: transparent;
                    height: 0;
                    width: 15px;
                    border: {
                        top: 7.5px solid transparent;
                        bottom: 7.5px solid transparent;
                        left: 15px solid black;
                        right: none;
                    };

                    &--up    {
                        width: 0;
                        height: 15px;
                        border: {
                            right: 7.5px solid transparent;
                            left: 7.5px solid transparent;
                            top: 15px solid black;
                            bottom: none;
                        }
                    }
                    &--right {
                        border: {
                            top: 7.5px solid transparent;
                            bottom: 7.5px solid transparent;
                            right: 15px solid black;
                            left: none;
                        }
                    }
                    &--down  {
                        width: 0;
                        height: 15px;
                        border: {
                            right: 7.5px solid transparent;
                            left: 7.5px solid transparent;
                            bottom: 15px solid black;
                            top: none;
                        }
                    }
                }

                &--fruit {
                    @include box(18px);
                    background-color: rgb(255, 149, 28);
                    border-radius: 50%;
                }

                &--wall {
                    @include box(25px);
                    border-radius: 5px;
                    background-color: gray;
                }
            }
        }
    }
}