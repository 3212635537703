.help {
    &__line {
        margin: 10px;
    }

    &__button {
        cursor: pointer;
        user-select: none;
        color: white;
        background-color: #43a047;
        &--alt {
            background-color: #1565c0;
        }
        border-radius: 4px;
        margin: 16px;
        border: none;
        padding: {
            left: 16px;
            right: 16px;
            top: 4px;
            bottom: 4px;
        }
        box-shadow: 
            0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0,0,0,.12);
    }
}