@mixin flex($dir:column, $justify:flex-start, $align:center) {
    display: flex;
    flex-direction: $dir;
    justify-content: $justify;
    align-items: $align;
}

.options {
    @include flex(column, center);
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.4s ease-in;
    
    
    &--hidden {
        visibility: hidden;
    }
    
    &__content {
        @include flex();
        width: 50%;
        padding: 24px;
        background-color: white;
        box-shadow: 
            0px 3px 3px -2px rgba(0,0,0,0.2),
            0px 3px 4px 0px rgba(0,0,0,0.12),
            0px 1px 8px 0px rgba(0,0,0,0.12);
        border-radius: 4px;
        transition: transform 0.2s ease-in;
        position: relative;
        transform: translateY(0);

        &--hidden {
            transform: translateY(-100%);
        }
    }

    &__button {
        cursor: pointer;
        user-select: none;
        color: white;
        background-color: #43a047;
        &--danger {
            background-color: #b71c1c;
        }
        border-radius: 4px;
        margin: 16px;
        border: none;
        padding: {
            left: 16px;
            right: 16px;
            top: 4px;
            bottom: 4px;
        }
        box-shadow: 
            0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0,0,0,.12);
    }

    &__field {
        @include flex(row, flex-start, center);
        width: 100%;
        & > * {
            margin: 16px;
        }
    }
}